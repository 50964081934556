export const environment = {
    production: true,
    liveedit: false,
    AUTH_BASE_URL: "https://www.drxwebservices.com/spa2024",
    WEB_SERVICE_BASE_URL: "https://www.drxwebservices.com/spa2024",
    LIVE_EDIT_CONFIG_URL: "https://www.drxwebservices.com/spa2024/v1/sites",
    ENROLLMENT_FORM_BASE_URL: "https://www.drxwebservices.com/spa2024/v1/enroll",
    MEMBER_SESSION_BASE_URL: "https://www.drxwebservices.com/spa2024/v1/session",
    ENROLLMENT_ASSETS_URL: "https://contentserver.destinationrx.com/drxproductcontent/phoenix/assets/enrollment2024",
    SOA_BASE_URL: "https://www.drxwebservices.com/spa2024/v1/soa",
    SSO_BASE_URL: "https://www.drxwebservices.com/spa2024/v1/sso",
    NEXTGEN_ENROLLMENT_ASSETS_URL: "https://contentserver.destinationrx.com/nextgen"
};
